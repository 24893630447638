<template>
  <v-row>
    <v-col class="mb-4">
        <v-tabs align-with-title>
          <v-tab to="/admin/data/products" exact>List</v-tab>
          <v-tab to="/admin/data/products/download" exact>Downloads</v-tab>
          <v-tab to="/admin/data/products/archived" exact>Archived</v-tab>
        </v-tabs>
      <router-view class="view" />
    </v-col>
  </v-row>
</template>

<script>
export default {
  data () {
    return {
      productSales: []
    }
  },
  async created () {}
}
</script>
